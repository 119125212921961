import React from 'react'

const listItemStyle = "flex items-start text-lg md:text-xl font-bold mb-6"
const squareShape = "flex-shrink-0 block w-4 h-4 bg-site-red mt-1 mr-3"
const pStyle = "text-base md:text-xl mb-6"

const Section831bNewLandingWhoCanBenefit = () => (
  <div className="bg-white pt-12 md:pt-52 px-4">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider">Who Can Benefit</h2>

      <div className="w-full flex flex-col justify-center mt-12">
        
        <div className="w-full max-w-2xl mx-auto">
          <p className={pStyle}>There is no shortage of risks in any industry. SRA helps the privately held small to middle market businesses that are the lifeblood of America. All businesses can benefit including:</p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <ul className="list-disc list-inside md:my-6 md:ml-6">
              <li className={listItemStyle}><span className={squareShape}></span>Entrepreneurs</li>
              <li className={listItemStyle}><span className={squareShape}></span>Property Managers</li>
              <li className={listItemStyle}><span className={squareShape}></span>Dental & Medical</li>
              <li className={listItemStyle}><span className={squareShape}></span>Professionals</li>
              <li className={listItemStyle}><span className={squareShape}></span>Manufacturers</li>
              <li className={listItemStyle}><span className={squareShape}></span>Self-Storage</li>
            </ul>
            <ul className="list-disc list-inside md:my-6 md:ml-6">
              <li className={listItemStyle}><span className={squareShape}></span>Professional Services</li>
              <li className={listItemStyle}><span className={squareShape}></span>Construction</li>
              <li className={listItemStyle}><span className={squareShape}></span>Service Industry</li>
              <li className={listItemStyle}><span className={squareShape}></span>Retail</li>
              <li className={listItemStyle}><span className={squareShape}></span>Technology</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingWhoCanBenefit