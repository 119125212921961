import React from 'react'

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold line-clamp-3 mb-6"
const viaStyle = "text-sm mb-10"

const Section831bNewLandingPressNews = () => (
  <div className="bg-white pt-16 md:pt-24 pb-12 md:pb-24">
    <div className="w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider mb-6">SRA 831(b) Plan News</h2>

    </div>

    <div className="w-11/12 md:w-5/12 lg:w-4/12 xl:w-11/12 max-w-screen-xl grid grid-cols-1 xl:grid-cols-3 gap-8 mx-auto mt-16">
      <div className="relative bg-white border border-gray-100 shadow-lg mb-10 xl:mb-0">
        <div>
          <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2023/06/15/top-five-strategies-to-navigate-business-interruptions/?sh=5f9e270c582c" target="_blank" rel="noopener noreferrer">
            <img
              src="https://imageio.forbes.com/specials-images/imageserve/680316225/Businessman-working-on-digital-tablet-in-office/960x0.jpg?format=jpg&width=960"
              alt="Top Five Strategies To Navigate Business Interruptions"
              className="w-full h-48"
            />
          </a>
        </div>

        <div className="p-6">
          <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2023/06/15/top-five-strategies-to-navigate-business-interruptions/?sh=5f9e270c582c" target="_blank" rel="noopener noreferrer">
            <h2 className={h2Style}>
            Top Five Strategies To Navigate Business Interruptions
            </h2>
          </a>
          <p className={viaStyle}>via <span className="text-lg font-medium">Forbes</span></p>
          <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2023/06/15/top-five-strategies-to-navigate-business-interruptions/?sh=5f9e270c582c" target="_blank" rel="noopener noreferrer">
            <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
              See More
            </button>
          </a>
        </div>
      </div>

      <div className="relative bg-white border border-gray-100 shadow-lg mb-10 xl:mb-0">
        <div>
          <a href="https://www.inc.com/john-hall/4-ways-entrepreneurs-can-protect-their-businesses.html" target="_blank" rel="noopener noreferrer">
            <img
              src="https://img-cdn.inc.com/image/upload/w_1080,ar_16:9,c_fill,g_auto,f_auto,q_auto:best/images/panoramic/GettyImages-1316107530_530949_lbi4o5.webp"
              alt="4 Ways Entrepreneurs Can Protect Their Businesses"
              className="w-full h-48"
            />
          </a>
        </div>

        <div className="p-6">
          <a href="https://www.inc.com/john-hall/4-ways-entrepreneurs-can-protect-their-businesses.html" target="_blank" rel="noopener noreferrer">
            <h2 className={h2Style}>
              4 Ways Entrepreneurs Can Protect Their Businesses
            </h2>
          </a>
          <p className={viaStyle}>via <span className="text-lg font-medium">Inc.</span></p>
          <a href="https://www.inc.com/john-hall/4-ways-entrepreneurs-can-protect-their-businesses.html" target="_blank" rel="noopener noreferrer">
            <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
              See More
            </button>
          </a>
        </div>
      </div>

      <div className="relative bg-white border border-gray-100 shadow-lg mb-10 xl:mb-0">
        <div>
          <a href="https://www.fa-mag.com/news/how-831-b--plans-empower-business-self-reliance-74448.html?section=40" target="_blank" rel="noopener noreferrer">
            <img
              src="https://sra2.sfo3.cdn.digitaloceanspaces.com/Newsroom/annie-spratt-IT6aov1ScW0-unsplash.jpg"
              alt="How 831(b) Plans Empower Small Business Self-Reliance As Insurers Exit More States"
              className="w-full h-48"
            />
          </a>
        </div>

        <div className="p-6">
          <a href="https://www.fa-mag.com/news/how-831-b--plans-empower-business-self-reliance-74448.html?section=40" target="_blank" rel="noopener noreferrer">
            <h2 className={h2Style}>
              How 831(b) Plans Empower Small Business Self-Reliance As Insurers Exit More States
            </h2>
          </a>
          <p className={viaStyle}>via <span className="text-lg font-medium">Financial Advisor Magazine</span></p>
          <a href="https://www.fa-mag.com/news/how-831-b--plans-empower-business-self-reliance-74448.html?section=40" target="_blank" rel="noopener noreferrer">
            <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
              See More
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingPressNews