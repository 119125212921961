import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const h2Style = "text-site-red text-2xl md:text-3xl mb-6 uppercase text-center font-semibold"
const pStyle = "text-base md:text-lg text-center"

const Section831bNewLandingFourPartTestUpdate = () => (
  <div className="bg-white pt-12 pb-12 md:pb-24 px-4">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider text-center">SRA 831(b) Plan 4-Part Test</h2>

      <div className='w-full max-w-2xl pt-8 pb-4 mx-auto'>
        <p className="text-base md:text-xl">Built on the foundations of Rev. Ruling 2009-26 and recent court rulings, SRA has created a stringent 4-Part Test to ensure 831(b) Plan compliance. Each part of the test is essential to successfully owning your 831(b) and the ability to elect under the 831(b) Tax Code.</p>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-4 gap-16 mt-6">
        <div>
          <div className='flex items-center justify-center mb-8'>
            <StaticImage
              src="../../images/icons/risk-transfer-icon.png"
              width={90}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
            />
          </div>
          <h2 className={h2Style}>Risk Transfer</h2>
          <p className={pStyle}>There must be a contractual transfer of risk from the operating company to an insurer. The SRA 831(b) Plan utilizes a Direct Writer that underwrites the risk and issues policy contracts to the operating company in exchange for premium.</p>
        </div>

        <div>
          <div className='flex items-center justify-center mb-8'>
            <StaticImage
              src="../../images/icons/risk-distribution-icon.png"
              width={90}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
            />
          </div>
          <h2 className={h2Style}>Risk Distribution</h2>
          <p className={pStyle}>In order to reduce the possibility that a single claim exceeds the amount of premiums collected, the 831(b) Plan must utilize the law of large numbers to disperse risk among unrelated parties. Each 831(b) Plan ARC administered by SRA is allied with others in risk co-ops that share each other’s risk on a pro rata basis.</p>
        </div>

        <div>
          <div className='flex items-center justify-center mb-8'>
            <StaticImage
              src="../../images/icons/fortuitous-risk-icon.png"
              width={90}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
            />
          </div>
          <h2 className={h2Style}>Fortuitous Risk</h2>
          <p className={pStyle}>The risk being contractually transferred must be fortuitous in nature and not considered to be an ordinary business risk. SRA’s Direct Writer underwrites many different risks that are fortuitous in nature including business interruptions, dispute resolution, political risk, brand damage and many more.</p>
        </div>

        <div>
          <div className='flex items-center justify-center mb-8'>
            <StaticImage
              src="../../images/icons/insurance-principles-icon.png"
              width={90}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
            />
          </div>
          <h2 className={h2Style}>Insurance Principles</h2>
          <p className={pStyle}>The 831(b) Plan must act just as an ordinary for-profit insurance company would by following the principles of insurance. These principles include: contractual transfer of risk, utilization of the law of large numbers, a defined methodology to determine premium, a defined claims process to determine covered losses, and managing reserves to generate investment income.</p>
        </div>
      </div>

      <div className='mt-16 mb-0 md:mb-4'>
        <div className="hidden xl:flex flex-col items-start justify-start">
          <Link to="/qualify">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3">
              <span className="default-text">Do You Qualify?</span>
              <span className="hover-text mx-7">Begin Now</span>
            </button>
          </Link>
        </div>
        
        <div className="xl:hidden flex flex-col items-center justify-center">                    
          <div className="mb-4">
            <p className="text-site-gray-dark text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
              <Link to="/qualify">
              <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                Begin Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingFourPartTestUpdate