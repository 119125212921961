import React from 'react'
import { Link } from 'gatsby'
import { VimeoPlayer } from 'reactjs-vimeo-player'
import { StaticImage } from "gatsby-plugin-image"

const pStyle = "text-xl mb-12"

const Section831bNewLandingIntro = () => (
  <div className="relative">
    <div className="bg-white pt-16 md:pt-24 pb-12 md:pb-24 px-4">
      <div className="w-11/12 md:w-10/12 max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto space-x-0 xl:space-x-8">
        <div className="relative w-full xl:w-1/2 mb-12 xl:mb-0 z-20 border border-gray-100">
          <VimeoPlayer id="553073290" width={640} height={480} responsive />

          <div className="hidden absolute left-0 bottom-0 -ml-14 mb-6 xl:flex justify-center -z-10">
            <StaticImage
              src="../../images/icons/dots.png"
              width={180}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icons"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
        </div>

        <div className="relative w-full xl:w-1/2">
          <div>
            <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider mb-8">What is an 831(b) Plan?</h2>
            <p className={pStyle}>Much like the 401(k) tax code allows an employer to set aside tax-deferred dollars for retirement, the 831(b) tax code allows a business to set aside tax-deferred dollars for underinsured and/or uninsured risks. The similarities are clear and business owners should consider the risk mitigation advantages.</p>

            <div className="hidden xl:flex flex-col items-start justify-start">
              <Link to="/qualify">
                <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3 mb-6">
                  <span className="default-text">Do You Qualify?</span>
                  <span className="hover-text mx-7">Begin Now</span>
                </button>
              </Link>
            </div>
            
            <div className="xl:hidden flex flex-col items-center justify-center mt-6">                    
              <div className="mb-6">
                <p className="text-site-gray-dark text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
                  <Link to="/qualify">
                  <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                    Begin Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingIntro