import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const pStyle2 = "text-base md:text-lg text-center"

const Section831bNewLandingBenefits = () => (
  <div className="bg-site-gray-lighter pt-12 md:pt-24 pb-12 px-4 md:-mb-48">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider text-center">Benefits of SRA 831(b) Plan</h2>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-12 mt-12 mb-8">

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
            <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">MITIGATE RISK MORE EFFICIENTLY</h3>
              <p className={pStyle2}>Traditional insurers cover a large portion of the risks business owners face. However, as many business owners learned during the COVID-19 pandemic, their policies often limited or excluded coverage. There are also many losses that insurers will not cover. An 831(b) Plan mitigates the risks business owners take each day, whether they realize it or not.</p>
            </div>
          </div>
        </div>

        <div className='bg-white p-6 rounded-3xl shadow-xl'>
          <div className="relative flex flex-col items-center w-11/12 mx-auto">
            <div className="relative mb-6">
              <StaticImage
                src="../../images/icons/checklist-icon.png"
                width={90}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="icon"
              />
            </div>
            <div className="w-full max-w-xl mx-auto">
              <h3 className="text-site-red text-xl md:text-2xl font-bold text-center mb-6">TAX-DEFERRED PLAN CONTRIBUTIONS</h3>
              <p className={pStyle2}>An 831(b) Plan allows a business owner to defer income to address tomorrow’s risks. Without an 831(b) Plan, business owners are self-insuring risks, such as a business interruption, with after-tax money from cash flow. With an 831(b) Plan, when catastrophe strikes, business owners can utilize these tax deferred reserves to weather the storm.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingBenefits