import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"

import LayoutLanding from "../components/layout-landing"
import Seo from "../components/seo"
import HeaderLanding from "../components/header/header-landing"
import HeroSection831bNewLanding from "../components/sections/hero-section-831b-new-landing"
import Section831bNewLandingIntro from "../components/sections/section-831b-new-landing-intro"
import Section831bNewLandingBenefits from "../components/sections/section-831b-new-landing-benefits"
import Section831bNewLandingFourPartTestUpdate from "../components/sections/section-831b-new-landing-4-part-test"
import Section831bNewLandingTestimonials from "../components/sections/section-831b-new-landing-testimonials"
import Section831bNewLandingWhoCanBenefit from "../components/sections/section-831b-new-landing-who-can-benefit"
import Section831bNewLandingPressNews from "../components/sections/section-831b-new-landing-press-news"

const Page831bPlanLandingTwo = () => {
  const data = useStaticQuery(graphql`
    query Page831bPlanLandingTwoQuery {
      imgHero: file(relativePath: { eq: "backgrounds/831b-plan-business-owners.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

const imgHero = getImage(data.imgHero)
const imgSrc = getSrc(data.imgHero)

  return (
    <LayoutLanding>
      <Seo
        title="SRA 831(b) Plan"
        description="Much like the 401(k) tax code allows an employer to set aside tax-deferred dollars for retirement, the 831(b) tax code allows a business to set aside tax-deferred dollars for underinsured and/or uninsured risks. The similarities are clear and business owners should consider the risk mitigation advantages."
        image={imgSrc}
        noIndex
      />

      <HeaderLanding />

      <HeroSection831bNewLanding
        title="Tax deferral today to address tomorrow’s risks."
        subTitle="SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies."
        imgSrc={imgHero}
      />

      <Section831bNewLandingIntro />

      <Section831bNewLandingBenefits />

      <Section831bNewLandingWhoCanBenefit />

      <Section831bNewLandingFourPartTestUpdate />

      <Section831bNewLandingTestimonials />

      <Section831bNewLandingPressNews />
    </LayoutLanding>
  )
}

export default Page831bPlanLandingTwo
