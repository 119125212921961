import React from 'react'
import { Link } from 'gatsby'
import { VimeoPlayer } from 'reactjs-vimeo-player'
import { StaticImage } from "gatsby-plugin-image"

const pStyle = "text-base md:text-xl mb-6"

const Section831bNewLandingTestimonials = () => (
  <div className="bg-site-gray-lighter pt-12 md:pt-24 pb-12 md:pb-24 px-4">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-site-gray-dark text-4xl md:text-5xl font-extrabold tracking-wider mb-6 text-center">SRA 831(b) Plan Testimonials</h2>

      <div className="w-11/12 flex flex-col xl:flex-row items-center mx-auto space-x-0 xl:space-x-8 mt-16">
        <div className="relative w-full xl:w-1/2 z-20 border border-gray-100">
          <VimeoPlayer id="575087963" width={640} height={480} responsive />

          <div className="hidden absolute left-0 bottom-0 -ml-14 mb-6 xl:flex justify-center -z-10">
            <StaticImage
              src="../../images/icons/dots.png"
              width={180}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
        </div>

        <div className="relative w-full xl:w-1/2">
          <div>
            <h2 className="text-site-gray-dark text-2xl md:text-4xl font-extrabold tracking-wider mb-8 mt-6 xl:mt-0">From the CPA...</h2>
            <p className={pStyle}>As a business owner, I think you should seriously consider 831(b)s primary because of the risk mitigation tools, but as a CPA its hard for me to ignore the blaring tax benefits that are coming down the pike from this as well. We shouldn't run away from it simply because there are good tax benefits. I would implore you to take a moment and understand it.</p>
          </div>
        </div>
      </div>

      <div className="w-11/12 flex flex-col-reverse xl:flex-row items-center mx-auto space-x-0 xl:space-x-8 mt-16">
        <div className="relative w-full xl:w-1/2">
          <div>
            <h2 className="text-site-gray-dark text-2xl md:text-4xl font-extrabold tracking-wider mb-8 mt-6 xl:mt-0">From the Tax Attorney...</h2>
            <p className={pStyle}>Every time the government says I have to do something that’s a tax on the business owner. An 831(b) is the only mechanism I have ever found - candidly - that will allow me to own 100% as the business owner, let me control it, provide a massive amount of intangible risk protection, and also get significant tax benefits, as well as the back end benefit of being able to pull that money out for all sorts of reasons.</p>
          </div>
        </div>

        <div className="relative w-full xl:w-1/2 z-20 border border-gray-100">
          <VimeoPlayer id="575090249" width={640} height={480} responsive />

          <div className="hidden absolute right-0 bottom-0 -mr-14 mb-6 xl:flex justify-center -z-10">
            <StaticImage
              src="../../images/icons/dots.png"
              width={180}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
        </div>
      </div>

      <div className="w-11/12 flex flex-col xl:flex-row items-center mx-auto space-x-0 xl:space-x-8 mt-16">
        <div className="relative w-full xl:w-1/2 z-20 border border-gray-100">
          <VimeoPlayer id="575460833" width={640} height={480} responsive />

          <div className="hidden absolute left-0 bottom-0 -ml-14 mb-6 xl:flex justify-center -z-10">
            <StaticImage
              src="../../images/icons/dots.png"
              width={180}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="icon"
              className="w-2/3 md:w-full mx-auto"
            />
          </div>
        </div>

        <div className="relative w-full xl:w-1/2">
          <div>
            <h2 className="text-site-gray-dark text-2xl md:text-4xl font-extrabold tracking-wider mb-8 mt-6 xl:mt-0">From the Financial Advisor...</h2>
            <p className={pStyle}>I agree with SRA that in the next decade, an 831(b) will be standard practice for business owners. The days of a financial advisor that just looks at stocks, bonds, and mutual funds and doesn’t address business liability, cash flow, and tax planning [are over]. You need to be on the bleeding edge of financial advising.</p>
          </div>
        </div>
      </div>

      <div className='mt-8 md:mt-16 mb-4'>
        <div className="hidden xl:flex flex-col items-start justify-start">
          <Link to="/qualify">
            <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-20 py-3">
              <span className="default-text">Do You Qualify?</span>
              <span className="hover-text mx-7">Begin Now</span>
            </button>
          </Link>
        </div>
        
        <div className="xl:hidden flex flex-col items-center justify-center">                    
          <div className="mb-4">
            <p className="text-site-gray-dark text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
              <Link to="/qualify">
              <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                Begin Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Section831bNewLandingTestimonials